import React, { Fragment } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { Clients, TwoColumns, ContentGrid, Seo } from "../components"
import { Colors, Fonts } from "../theme/styles"
import { Container, Column, SEOTitle, Subtitle, Button } from "../theme"
import { FaLaptopCode, FaMobile, FaEnvelope } from "react-icons/fa"

const ServiceCard = styled.div`
  display: flex;
  justify-content: center;
  background: transparent;
  align-items: center;
  border: 2px solid ${Colors.lightBlue};
  width: 100%;
  height: auto;
  padding: 18px;
  color: ${Colors.black};
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: ${Fonts.montserrat};
  cursor: pointer;
  margin-right: ${props => (props.marginRight ? "10px" : "0")};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  &:hover {
    background: ${Colors.darkBlue};
    // background: ${Colors.lightBlue};
    color: ${Colors.white};
    transition: 0.3s;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`

const StyledLi = styled.li`
  margin-top: 10px;
  font-size: 16px;
`

const StyledText = styled(Subtitle)`
  color: inherit;
`

const ServicesGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  @media (max-width: 769px) {
    display: block;
    // flex-direction: column;
  }
`

const Services = () => {
  return (
    <Layout title="Services" type="Services">
      <Seo
        title="Digital Marketing services for businesses looking to acquire more leads and increase their revenue."
        description="Companies have marketing services at their disposal from the CheshTech Digital Agency Team! Get digital marketing, web development, and application services to acquire more leads and increase your revenue."
      />
      <TwoColumns
        type="services"
        title="We Provide Solutions That Grow Your Business"
        paragraph1="Are you looking to increase your revenue and grow your business? Here at CheshTech we offer services that are intended to help you focus on what you do best, while we do the dirty work of growing your business and revenue. We focus on building a highly effective sales funnel for your business that's designed for growth and driving traffic to your website or landing page."
        paragraph2="Solutions are what we do and your success is our success. Our focus is to understand the ins and outs of your business, target market, consumer pain points, user experience and your short and long term goals. It's our priority to work with you personally. We offer digital marketing, websites built with the latest technology, mobile applications, product and feature development, and branding to vault your company ahead of your competitors. Interested? Take a look at our services below and let's get started! "
      />
      <Content />
      <Clients />
    </Layout>
  )
}

export default Services

const data = [
  {
    icon: <FaMobile size={60} />,
    service: "Product Design & Development",
    list1: "Build your MVP efficiently with QA testing",
    list2: "Development of both native and web applications",
    list3: "Cloud strategy and system archeticture consulting",
    link: "web-mobile-application-development",
    margin: true,
  },
  {
    icon: <FaLaptopCode size={60} />,
    service: "Website Design & Development",
    list1: "Conversion centric websites built with the latest technology",
    list2: "Website audits and strategies",
    list3: "Headless CMS technology to leap your competitors",
    link: "website-design-development",
    margin: true,
  },
  {
    icon: <FaEnvelope size={60} />,
    service: "Digital Marketing",
    list1:
      "Social media marketing and branding for organic growth and community building",
    list2:
      "Overall marketing and funnel strategy to drive your target market to you",
    list3: "Data driven marketing strategies",
    link: "marketing-services",
    margin: true,
  },
]

const Content = () => {
  return (
    <Fragment>
      <Container>
        <Column alignitems="center" margin="0 0 70px 0">
          <SEOTitle>How we help to solve your problems</SEOTitle>
          <ServicesGrid>
            {data.map((key, index) => (
              <Link to={key.link}>
                <ServiceCard key={index}>
                  <Column alignitems="center">
                    <StyledText textAlign="center">{key.service}</StyledText>
                    <Column margin="10px 0">{key.icon}</Column>
                    <ul>
                      <StyledLi>{key.list1}</StyledLi>
                      <StyledLi>{key.list2}</StyledLi>
                      <StyledLi>{key.list3}</StyledLi>
                    </ul>
                    <Button background={Colors.black}>Learn More</Button>
                  </Column>
                </ServiceCard>
              </Link>
            ))}
          </ServicesGrid>
        </Column>
      </Container>
      <ContentGrid
        type="services"
        background={Colors.lightBlue}
        color={Colors.white}
        seoTitle="Growing Businesses Through Digital Products and Marketing Strategies"
      />
    </Fragment>
  )
}
